import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PageWrapper from '../components/Structure/PageWrapper'

export default class Article extends Component {
	render() {
		const {
			data: {
				craft: { entry }
			}
		} = this.props

		return (
			<PageWrapper
				location={this.props.location}
				entry={entry}
				imageObject={
					!entry.featuredImage || !entry.featuredImage.length
						? null
						: entry.featuredImage[0]
				}
				title={entry.title}
				matrixBuilder={entry.matrixBuilder}
			/>
		)
	}
}

export const articleQuery = graphql`
	query QueryArticles($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Articles {
					title
					uri
					matrixBuilder {
						...MatrixBuilderQuery
					}
					# pancakePicker {
					# 	title
					# }
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`

// <div className="px-4 bg-blue-light">
// 				<Link to="/about">Back to Articles</Link>
// 				<h1>{entry.title}</h1>
// 				<Box>
// 					<pre>{JSON.stringify(entry, null, 2)}</pre>
// 				</Box>
// 			</div>
